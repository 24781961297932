import React from "react";
import { connect } from "react-redux";
import { appQueuedRedirect } from "../redux/app/actions";

const TestRedirectsPage = ({ appQueuedRedirect }) => {
	const go = () => {
		appQueuedRedirect({
			to: "https://bing.com",
			external: true,
			after: "login",
		});
	};
	return (
		<div>
			<button onClick={go}>Go</button>
		</div>
	);
};

const mapStateToProps = (state, ownprops) => {
	return {};
};

const mapDispatchToProps = {
	appQueuedRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestRedirectsPage);
